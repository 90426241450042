import { FC, useEffect } from 'react';
import Script from 'next/script';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { AppConfigService } from 'modules/appConfig/AppConfigService';

const rtbHouseKey = 'rtbhEvents';
const rtbHouseEventKey = 'rtbHouseLoaded';

const RtbHouse: FC = () => {
    const { rtbHouse: { set: setRtbHouse } } = useAnalytics();

    useEffect(() => {
        const onRtbLoaded = () => {
            if (!window[rtbHouseKey]) {
                window[rtbHouseKey] = window[rtbHouseKey] || [];
            }

            setRtbHouse({
                sendEvent: (event,) => {
                    window[rtbHouseKey].push(event);
                },
            });

        };

        document.addEventListener(rtbHouseEventKey, onRtbLoaded);

        return () => {
            document.removeEventListener(rtbHouseEventKey, onRtbLoaded);
        };
    },[setRtbHouse]);


    return <Script
        id="script-rtbhouse-event"
        strategy="afterInteractive"
        async={true}
        type='text/javascript'
        dangerouslySetInnerHTML={{
            __html: `(function (w, d, dn, t) {
                    w[dn] = w[dn] || [];
                    w[dn].push({eventType: 'init', value: t, dc: ''});
                    var f = d.getElementsByTagName('script')[0], c = d.createElement('script');
                    c.async = true;
                    c.src = 'https://tags.creativecdn.com/${AppConfigService.getRtbHouseId()}.js';
                    f.parentNode.insertBefore(c, f);
                    c.addEventListener( 'load', () => { document.dispatchEvent(new Event('${rtbHouseEventKey}')); })
                })(window, document, '${rtbHouseKey}', '${AppConfigService.getRtbHouseId()}');`,
        }}
    />;
};

export default RtbHouse;