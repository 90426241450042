'use client';

import { FC, useEffect, useState } from 'react';
import useCookieBar from 'modules/state/app/hook/gdpr/useCookieBar';
import Script from 'next/script';
import {AppConfigService} from 'modules/appConfig/AppConfigService';

const EVENT_NAMES = {
    GOOGLE_TAG_MANAGER: 'gdprCookieBarAllowGoogleTagManager',
    FACEBOOK_PIXEL: 'gdprCookieBarAllowFacebookPixel',
    GEMIUS: 'gdprCookieBarAllowGemius',
    RTBHOUSE: 'gdprCookieBarAllowRtbHouse',
    LIVEMONITOR: 'gdprCookieBarAllowLivemonitor',
};

const Main: FC = () => {
    const [ isLibRemoteLoaded, setIsLibRemoteLoaded ] = useState<boolean>(false);
    const { allow } = useCookieBar();

    useEffect(() => {
        const handleOnAllowGoogleTagManager = () => {
            allow('googleTagManager');
        };

        const handleOnAllowFacebookPixel = () => {
            allow('facebookPixel');
        };

        const handleOnAllowGemius = () => {
            allow('gemius');
        };

        const handleOnAllowRtbHouse = () => {
            allow('rtbHouse');
        };

        const handleOnAllowLivemonitor = () => {
            allow('livemonitor');
        };

        document.addEventListener(EVENT_NAMES.GOOGLE_TAG_MANAGER, handleOnAllowGoogleTagManager);
        document.addEventListener(EVENT_NAMES.FACEBOOK_PIXEL, handleOnAllowFacebookPixel);
        document.addEventListener(EVENT_NAMES.GEMIUS, handleOnAllowGemius);
        document.addEventListener(EVENT_NAMES.RTBHOUSE, handleOnAllowRtbHouse);
        document.addEventListener(EVENT_NAMES.LIVEMONITOR, handleOnAllowLivemonitor);

        return () => {
            document.removeEventListener(EVENT_NAMES.GOOGLE_TAG_MANAGER, handleOnAllowGoogleTagManager);
            document.removeEventListener(EVENT_NAMES.FACEBOOK_PIXEL, handleOnAllowFacebookPixel);
            document.removeEventListener(EVENT_NAMES.GEMIUS, handleOnAllowGemius);
            document.removeEventListener(EVENT_NAMES.RTBHOUSE, handleOnAllowRtbHouse);
            document.removeEventListener(EVENT_NAMES.LIVEMONITOR, handleOnAllowLivemonitor);
        };
    }, [allow]);

    const notOnProduction = AppConfigService.getEnvironment() !== 'production';

    useEffect(() => {
        if(notOnProduction) {
            allow('googleTagManager');
            allow('facebookPixel');
            allow('gemius');
            allow('rtbHouse');
            allow('livemonitor');
        }
    }, [allow, notOnProduction]);

    if(notOnProduction){
        return null;
    }

    return <>
        <Script
            id="script-gdpr-cookie-bar-lib"
            strategy="afterInteractive"
        >{`
            (function() {
                "use strict";function _typeof(t){return(_typeof="function"==typeof Symbol&&"symbol"==typeof Symbol.iterator?function(t){return typeof t}:function(t){return t&&"function"==typeof Symbol&&t.constructor===Symbol&&t!==Symbol.prototype?"symbol":typeof t})(t)}!function(){var t=function(){var t,e,o=[],n=window,r=n;for(;r;){try{if(r.frames.__tcfapiLocator){t=r;break}}catch(t){}if(r===n.top)break;r=r.parent}t||(!function t(){var e=n.document,o=!!n.frames.__tcfapiLocator;if(!o)if(e.body){var r=e.createElement("iframe");r.style.cssText="display:none",r.name="__tcfapiLocator",e.body.appendChild(r)}else setTimeout(t,5);return!o}(),n.__tcfapi=function(){for(var t=arguments.length,n=new Array(t),r=0;r<t;r++)n[r]=arguments[r];if(!n.length)return o;"setGdprApplies"===n[0]?n.length>3&&2===parseInt(n[1],10)&&"boolean"==typeof n[3]&&(e=n[3],"function"==typeof n[2]&&n[2]("set",!0)):"ping"===n[0]?"function"==typeof n[2]&&n[2]({gdprApplies:e,cmpLoaded:!1,cmpStatus:"stub"}):o.push(n)},n.addEventListener("message",(function(t){var e="string"==typeof t.data,o={};if(e)try{o=JSON.parse(t.data)}catch(t){}else o=t.data;var n="object"===_typeof(o)&&null!==o?o.__tcfapiCall:null;n&&window.__tcfapi(n.command,n.version,(function(o,r){var a={__tcfapiReturn:{returnValue:o,success:r,callId:n.callId}};t&&t.source&&t.source.postMessage&&t.source.postMessage(e?JSON.stringify(a):a,"*")}),n.parameter)}),!1))};"undefined"!=typeof module?module.exports=t:t()}();

                window._sp_queue = [];
                window._sp_ = {
                    config: {
                        accountId: 1688,
                        baseEndpoint: 'https://privacy.nehnutelnosti.sk',
                        events: {},
                        consentLanguage: 'sk',
                        gdpr: {
                            groupPmId: 916223
                        }
                    }
                }
            })();
        `}</Script>
        <Script
            id="script-gdpr-cookie-bar-lib-remote"
            strategy="afterInteractive"
            src="https://privacy.nehnutelnosti.sk/unified/wrapperMessagingWithoutDetection.js"
            onLoad={() => { setIsLibRemoteLoaded(true); }}
        />

        {isLibRemoteLoaded && (
            <Script
                id="script-gdpr-cookie-bar-main"
                strategy="afterInteractive"
            >{`
                (function() {
                    if (typeof __tcfapi === 'undefined') {
                        return;
                    }

                    __tcfapi('addEventListener', 2, function (tcData, success) {
                        if (tcData.gdprApplies === true) {
                            if (success && (tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'useractioncomplete')) {
                                __tcfapi('getCustomVendorConsents', 2, function (vendorConsents, success) {
                                    if (success) {
                                        if (vendorConsents.grants["5e542b3a4cd8884eb41b5a72"].vendorGrant === true) {
                                            document.dispatchEvent(new Event('${EVENT_NAMES.GOOGLE_TAG_MANAGER}'));
                                        }

                                        if (vendorConsents.grants["5fac56cd1ba05165880458ad"].vendorGrant === true) {
                                            document.dispatchEvent(new Event('${EVENT_NAMES.FACEBOOK_PIXEL}'));
                                        }

                                        if (vendorConsents.grants["5f1b2fbeb8e05c30686fd6f5"].vendorGrant === true) {
                                            document.dispatchEvent(new Event('${EVENT_NAMES.GEMIUS}'));
                                        }

                                        if (vendorConsents.grants["5ee15bc6b8e05c164c398ae3"].vendorGrant === true) {
                                            document.dispatchEvent(new Event('${EVENT_NAMES.RTBHOUSE}'));

                                            // TODO when enable this?
                                            document.dispatchEvent(new Event('${EVENT_NAMES.LIVEMONITOR}'));
                                        }
                                    }
                                });
                            }
                        } else {
                            document.dispatchEvent(new Event('${EVENT_NAMES.GOOGLE_TAG_MANAGER}'));
                            document.dispatchEvent(new Event('${EVENT_NAMES.FACEBOOK_PIXEL}'));
                            document.dispatchEvent(new Event('${EVENT_NAMES.GEMIUS}'));
                            document.dispatchEvent(new Event('${EVENT_NAMES.RTBHOUSE}'));
                            document.dispatchEvent(new Event('${EVENT_NAMES.LIVEMONITOR}'));
                        }
                    });
                })();
            `}</Script>
        )}
    </>;
};

export default Main;
