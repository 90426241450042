import {FC, useEffect} from 'react';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {AppConfigService} from 'modules/appConfig/AppConfigService';

const GoogleTagManager: FC = () => {
    const { gtm: { set: setGtm } } = useAnalytics();

    useEffect(() => {
        import('react-gtm-module')
            .then((x) => x.default)
            .then((TagManager) => {
                TagManager.initialize({
                    gtmId: AppConfigService.getGoogleTagManagerId(),
                });
                setGtm({
                    sendEvent: event => {
                        TagManager.dataLayer({
                            dataLayer: event,
                        });
                    },
                });
            });
    }, [setGtm]);
    return null;
};


export default GoogleTagManager;
