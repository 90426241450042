import {FC, useEffect} from 'react';
import {AppConfigService} from 'modules/appConfig/AppConfigService';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';

const FacebookPixel: FC = () => {
    const { facebookPixel: { set: setFacebookPixel } } = useAnalytics();

    useEffect(() => {
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init(AppConfigService.getFbPixelId());

                setFacebookPixel({
                    sendEvent: (params: unknown[]) => {
                        ReactPixel.fbq(...params);
                    },
                    pageView: () => {
                        ReactPixel.pageView();
                    },
                });
            });
    },[setFacebookPixel]);

    return null;
};


export default FacebookPixel;
