import {FC} from 'react';
import useLoading from 'modules/state/app/hook/useLoading';
import useHeaderLoading from 'modules/state/app/hook/useHeaderLoader';
import {Loading} from 'components/layout/header/components/loading/Loading';
import {AppBar, Theme, Toolbar, Container, useMediaQuery, useTheme} from '@mui/material';

interface IHeaderWrapper {
    children: JSX.Element;
}

export const HeaderWrapper: FC<IHeaderWrapper> = ({children}) => {
    const {isLoading} = useLoading();
    const {percentage} = useHeaderLoading();
    const {breakpoints, palette} = useTheme();
    const isMdDown = useMediaQuery(breakpoints.down('md'));

    const headerLoaderStyle = () => {
        const windowWidth = percentage === 0 ? 101 : 100;
        return {boxShadow: `${-windowWidth + percentage}vw 0px 0px 4px ${palette.backgroundPrimary.main}`};
    };

    return <AppBar
        variant="header"
        enableColorOnDark
        sx={(theme: Theme) => ({
            top: '0',
            left: '0',
            width: '100%',
            position: 'relative',
            boxSizing: 'content-box',
            boxShadow: headerLoaderStyle,
            transition: 'box-shadow 0.3s ease-in-out',
            [theme.breakpoints.down('md')]: {position: 'fixed'},
        })}>
        <Toolbar variant={isMdDown ? 'dense' : 'regular'} disableGutters={true} sx={{color: '#222'}}>
            <Container>
                {children}
            </Container>
        </Toolbar>

        {isLoading && <Loading/>}

    </AppBar>;
};
